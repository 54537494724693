import { ITEM_TYPE, CHECKLIST_SECTION } from './definitions'
import {
  isAValidSection,
  isAValidType,
  isAValidQualityCheckAction,
  isAValidQualityCheckPayload
} from './validations'

export function evaluateQualityEquation (equation, checklist) {
  // Make map of codes that are checked
  const checkedCodes = new Set()
  const items = [].concat(
    checklist.history || [],
    checklist.physical_exam || [],
    checklist.treatment || [],
    checklist.studies || [],
    checklist.education || []
  )
  for (var i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.checked && item.quality_codes && item.quality_codes.length > 0) {
      for (var j = 0; j < item.quality_codes.length; j++) {
        checkedCodes.add(item.quality_codes[j])
      }
    }
  }

  // Create a function to descend through the equation
  function evaluateNode (nodeParam = {}) {
    // debug({evaluateNodeStart: node})

    const node = nodeParam || {}

    const items = node.items || []
    const codes = node.codes || []
    let values, val
    const children = []
    let missing = []
    const tree = { name: '', type: node.type }
    switch (node.type) {
      case 'equation_and':
        values = items.map(evaluateNode)
        val = values.every((v) => v.val)
        if (values.some((v) => v.val)) {
          missing = values
            .map((v) => v.missing)
            .reduce((a, b) => a.concat(b), [])
        }
        // tree maintenance
        tree.name = 'AND'
        children.push(...values.map((v) => v.tree))
        break
      case 'equation_or':
        values = items.map(evaluateNode)
        val = values.some((v) => v.val)
        if (val) {
          missing = values
            .map((v) => v.missing)
            .reduce((a, b) => a.concat(b), [])
        }
        // tree maintenance
        tree.name = 'OR'
        children.push(...values.map((v) => v.tree))
        break
      case 'equation_any':
        val = codes.some((code) => checkedCodes.has(code))
        if (!val) {
          missing = missing.concat(
            codes.filter((code) => !checkedCodes.has(code))
          )
        }
        // tree maintenance
        tree.name = 'ANY'
        children.push(
          ...codes.map((code) =>
            checkedCodes.has(code)
              ? { name: code, val: true }
              : { name: code, val: false }
          )
        )
        break
      case 'equation_all':
        val = codes.every((code) => checkedCodes.has(code))
        if (!val) {
          missing = missing.concat(
            codes.filter((code) => !checkedCodes.has(code))
          )
        }
        // tree maintenance
        tree.name = 'ALL'
        children.push(
          ...codes.map((code) =>
            checkedCodes.has(code)
              ? { name: code, val: true }
              : { name: code, val: false }
          )
        )
        break
      case 'equation_not':
        /**
         * We had to unify the field name to improve the graphql compatibility
         * So, we need to replace the old logic taking the first element from the array
         * instead of using 'item' as a different key
         */
        const evaluation = evaluateNode(items[0])
        val = !evaluation.val // (╯°□°）╯︵ ┻━┻
        missing = evaluation.missing // pass through
        // tree maintenance
        tree.name = 'NOT'
        children.push(evaluation.tree)
        break
      case 'equation_xor':
        values = items.map(evaluateNode)
        val = values.map((v) => v.val).reduce((a, b) => a + b, 0) === 1
        // tree maintenance
        tree.name = 'XOR'
        children.push(...values.map((v) => v.tree))
        break
      default:
        val = false
    }
    // debug({evaluateNodeEnd: node, val, missing, children})
    if (children.length > 0) tree.children = children
    if (val) tree.val = val
    return { val, missing, tree }
  }

  return evaluateNode(equation)
}

/**
 * When we cancel orders we need to uncheck the item
 * which triggered it. V1 did this update in the client.
 * We'll use the cancellation to update both (checklists and order)
 * This would be a checklist module functionality but the module
 * was created to work with its own instance of a checklist.
 * This is more like a utility
 * @param {Checklist} checklist
 * @param {Object} order
 * @param {Any of ChecklistSections} [section] checklist section
 */
export const getItemByGuideline = (checklist, guideline, section) => {
  if (section && !isAValidSection(section)) return null

  const items = section
    ? (checklist || {})[section]
    : getAllChecklistItem(checklist)

  if (!Array.isArray(items)) return null

  const hash = hashChecklistItem(guideline)
  const found = items.find((item) => hash === hashChecklistItem(item))

  return found
}

export const hashChecklistItem = (item) => {
  if (!isAValidType((item || {}).type)) return null

  switch (item.type) {
    case ITEM_TYPE.DRUG:
      return hashGuideline(item)

    case ITEM_TYPE.PROCEDURE:
    case ITEM_TYPE.STUDY:
      return hashEntry(item)

    case ITEM_TYPE.CITATION:
    case ITEM_TYPE.DIVIDER:
    case ITEM_TYPE.TEXT:
    default:
      return item
  }
}

const hashGuideline = (item) => {
  const {
    key,
    name,
    pre_text, //eslint-disable-line
    dose,
    dose_units, //eslint-disable-line
    dose_range_min, //eslint-disable-line
    dose_range_max, //eslint-disable-line
    dose_max, //eslint-disable-line
    max_dose, //eslint-disable-line
    max_dose_units, //eslint-disable-line
    doses_per_day, //eslint-disable-line
    as_needed, //eslint-disable-line
    max_doses, //eslint-disable-line
    days
  } = item
  return JSON.stringify({
    key,
    name,
    pre_text,
    dose,
    dose_units,
    dose_range_min,
    dose_range_max,
    dose_max,
    max_dose,
    max_dose_units,
    doses_per_day,
    as_needed,
    max_doses,
    days
  })
}

const hashEntry = (item) => {
  const { name, type, key } = item
  return JSON.stringify({ name, type, key })
}

const getAllChecklistItem = (checklist) => {
  if (!checklist) return null

  const allItems = Object.values(CHECKLIST_SECTION).reduce(
    (items, currentSection) => {
      if (Array.isArray(checklist[currentSection])) {
        return [...items, ...checklist[currentSection]]
      }

      return items
    },
    []
  )

  return allItems
}

/*
 * Quality checks have an entry called: Actions
 * We need to run those actions if the check passes
 *
 * This fn helps us to validate each action
 * and it corresponding paryload
 *
 * It takes a quality check and returns
 * an array of objects with the standarized action type
 * and it corresponding payload
 *
 * @param {Object} qualityCheck Checklist qualityCheck
 * @returns {Array<Object>} { type, payload }
 */
export const getQualityCheckAction = (qualityCheck) => {
  if (typeof (qualityCheck || {}).actions !== 'object') {
    return []
  }

  const actions = Object.keys(qualityCheck.actions || {})
    // filter invalid actions
    .filter((actionType) => {
      const payload = qualityCheck.actions[actionType]

      return (
        isAValidQualityCheckAction(actionType) &&
        isAValidQualityCheckPayload(actionType, payload)
      )
    })
    // format them
    .map((type) => {
      const payload = qualityCheck.actions[type]
      return {
        type,
        payload
      }
    })

  return actions
}

/**
 *
 * @param {Object} checklist
 * @param {String} itemId
 * @param {String} section One of the CHECKLIST_SECTION defined in definitions.js
 * @returns Object with historyCodes and physicalExamCodes. Or null
 */
export const getItemCodesBySection = (checklist, itemId, section) => {
  if (!isAValidSection(section)) return null

  if (!Array.isArray((checklist || {})[section])) return null

  const item = checklist[section].find((item) => item.key === itemId)
  if (typeof item === 'object') {
    // eslint-disable-next-line
    const { history_codes, physical_exam_codes } = item;

    return {
      historyCodes: history_codes,
      physicalExamCodes: physical_exam_codes
    }
  }

  return null
}
