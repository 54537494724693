import { gql } from '@apollo/client'
import { FullReference } from './common'
import { FullDrugGuideline } from './rxs'

export const FullQualityChecksEquationItems = gql`
  fragment FullQualityChecksEquationItems on QualityChecksEquationItems {
    type
    codes
  }
`
/**
 * Quality eq items are a kind of recursive type
 * in Graphql it's not possible to write a recursive query of unlimited depth
 * This GraphQL design pattern allows us to specify a fixed limit on recursion
 * depth by nesting each level of depth in the fragment definition
 * See: https://github.com/graphql/graphql-spec/issues/91 for explanation
 */
export const RecursiveQualityChecksEquationItems = gql`
  fragment RecursiveQualityChecksEquationItems on QualityChecksEquationItems {
    ...FullQualityChecksEquationItems
    items {
      ...FullQualityChecksEquationItems
      items {
        ...FullQualityChecksEquationItems
        items {
          ...FullQualityChecksEquationItems
          items {
            ...FullQualityChecksEquationItems
            items {
              ...FullQualityChecksEquationItems
              items {
                ...FullQualityChecksEquationItems
                items {
                  ...FullQualityChecksEquationItems
                }
              }
            }
          }
        }
      }
    }
  }
  ${FullQualityChecksEquationItems}
`

export const FullQualityCheck = gql`
  fragment FullQualityCheck on QualityChecks {
    name
    message
    show_alert
    actions {
      clinical_status
    }
    colors {
      error
      true
    }
    equation {
      type
      items {
        ...RecursiveQualityChecksEquationItems
      }
    }
  }
  ${RecursiveQualityChecksEquationItems}
`

export const FullChecklistEntry = gql`
  fragment FullChecklistEntry on ChecklistEntry {
    type
    name {
      type
      text
    }
    reference {
      ...FullReference
    }
    general_tags {
      text
    }
    billing_tags
    history_codes
    physical_exam_codes
    quality_codes
    checked
    key
  }
  ${FullReference}
`

export const BasicChecklist = gql`
  fragment BasicChecklist on Checklist {
    id
    name {
      text
    }
    background {
      text
    }
    category {
      text
    }
    general_tags {
      text
    }
  }
`

export const FullChecklist = gql`
  fragment FullChecklist on Checklist {
    id
    name {
      text
    }
    background {
      text
    }
    differential_diagnosis {
      type
      name {
        text
      }
    }
    history {
      ...FullChecklistEntry
    }
    physical_exam {
      ...FullChecklistEntry
    }
    studies {
      ...FullChecklistEntry
    }
    treatment {
      ... on SystemDrugGuideline {
        ...FullDrugGuideline
      }
      ... on ChecklistEntry {
        ...FullChecklistEntry
      }
    }
    education {
      ...FullChecklistEntry
    }
    category {
      text
    }
    authors
    general_tags {
      text
    }
    quality_checks {
      ...FullQualityCheck
    }
  }
  ${FullChecklistEntry}
  ${FullDrugGuideline}
  ${FullQualityCheck}
`
