import React from 'react'
import { Box, Button, Heading, Text, useTimeout } from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { useSession } from '../Auth'
import { useActivationCheckout } from '.'
import { ErrorViewer } from '..'


const messages = defineMessages({
  paymentFailed: {
    id: 'DomesticSetup.label_payment_failed',
    defaultMessage: 'Payment failed',
  },
  paymentSuccess: {
    id: 'DomesticSetup.label_payment_success',
    defaultMessage: 'Payment success',
  },
  retry: {
    id: 'NonStaffUI.label_retry',
    defaultMessage: 'Retry',
  }
})

interface PaymentResultProps {
  isSuccess: boolean
}

export const PaymentResult: React.FC<PaymentResultProps> = (props) => {
  const { isSuccess } = props
  const intl = useIntl()

  const { isLoading, checkout, error } = useActivationCheckout()

  if (isSuccess) {
    return <PaymentSuccess />
  }

  return <Box >
    <Heading as="h1" size="xl" textAlign="center">
      {intl.formatMessage(messages.paymentFailed)}
    </Heading>

    <Text my={2}>{'We could not process your payment'}</Text>

    <Button
      onClick={checkout}
      isLoading={isLoading}
      mt={3}
      colorScheme={'brand'}
      isFullWidth
    >
      {intl.formatMessage(messages.retry)}
    </Button>

    {
      error &&
      <ErrorViewer title={error} />
    }
  </Box>
}


const PaymentSuccess: React.FC = () => {
  const intl = useIntl()

  const { refresh } = useSession()
  useTimeout(() => {
    refresh()
  }, 5000)

  return <Box >
    <Heading as="h1" size="xl" textAlign="center">
      {intl.formatMessage(messages.paymentSuccess)}
    </Heading>
  </Box>
}