export const getAuthUri = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return '/api/v2.0'
  }

  const origin = window.location.hostname

  return `http://${origin}:8080/api/v2.0`
}

export const getUnAuthUri = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return '/unauth'
  }

  const origin = window.location.hostname

  return `http://${origin}:8080/unauth`
}

export const getSetupUri = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return '/setup'
  }

  const origin = window.location.hostname

  return `http://${origin}:8080/setup`
}
